import React from 'react'
import { graphql } from 'gatsby'
import classNames from 'classnames'
import Layout from '../components/Layout/Layout'
import Hero from '../components/Hero'

const ns = `page`

const PagePost = ({ data }) => {
  const { markdownRemark } = data
  const rootClassnames = classNames({
    [`${ns}`]: true,
  })

  return (
    <Layout>
      <div className={rootClassnames}>
        <Hero
          hero={{
            urlMobile:
              markdownRemark.frontmatter.hero.hero_image_mobile.publicURL,
            urlDesktop:
              markdownRemark.frontmatter.hero.hero_image_desktop.publicURL,
          }}
        />
        <div className={`${ns}__container`}>
          <h1 className="page--title">{markdownRemark.frontmatter.title}</h1>
          <div className={`${ns}__content-container`}>
            <div
              className={`${ns}__sections`}
              dangerouslySetInnerHTML={{
                __html: markdownRemark.html,
              }}
            />
          </div>
        </div>
      </div>
    </Layout>
  )
}

export default PagePost

export const PagePostQuery = graphql`
  query PagePost($id: String!) {
    markdownRemark(id: { eq: $id }) {
      html
      frontmatter {
        title
        hero {
          hero_image_desktop {
            publicURL
          }
          hero_image_mobile {
            publicURL
          }
        }
      }
    }
  }
`
